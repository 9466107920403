import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getLogData } from 'src/app/shared/getLogData';
// import { log } from 'util';
import { auditcls } from './auditLog';
import { CommonService } from 'src/app/core/services/common.service';
@Injectable({
  providedIn: 'root'
})
export class AuditService {
  // obj = new auditcls();
  public auditObj = new auditcls();
  public ip: string = "";
  public user: string = "";
  public ClientBrowser: any;
  public fullname: string = "";
  public userid: string = '';
  public country: any = '';
  public countryCode: any = '';
  public continent: any = '';
  public region: any = '';
  public city: any = '';
  public tenantid: any = '';
  public patientid: any = '';
  public workflow: any = '';
  // public city: any = '';
  // public module: string = "Dashboard";
  // public screen: string = "Dashboard";
  public georesult: any;

  ClientIP: any;
  message: any;
  useraction: any;
  token: any;
  token1: any
  headers: any;
  parsedToken: any;

  constructor(private http: HttpClient, private httpBackend: HttpBackend, private commonService: CommonService) {
    this.getSessionDetailsForAudit();
    this.parsedToken = this.token1;
    this.token = this.parsedToken.token
    this.headers = { headers: new Headers({ 'Authorization': 'Bearer ' + this.token }) };
  }

  getbroweserdata(georesult: any, name: any) {
    this.georesult = georesult;
    if (Object.keys(georesult).length != 0) {

      this.ip = georesult.query;
      this.country = georesult.country;
      this.countryCode = georesult.countryCode;
      this.continent = georesult.continent;
      this.region = georesult.region;
      this.city = georesult.city;
    }
    this.user = name;
  }

  getSessionDetailsForAudit() {
    this.ip = this.commonService.getSessionStorage("ip") ? this.commonService.getSessionStorage("ip") : '';
    this.user = this.commonService.getSessionStorage("usr") ? this.commonService.getSessionStorage("usr") : '';
    this.ClientBrowser = getLogData.getbroweser();
    this.fullname = this.commonService.getSessionStorage("fn") ? this.commonService.getSessionStorage("fn") : '';
    this.userid = this.commonService.getSessionStorage("usid") ? this.commonService.getSessionStorage("usid") : '';
    this.country = this.commonService.getSessionStorage("country") ? this.commonService.getSessionStorage("country") : '';
    this.countryCode = this.commonService.getSessionStorage("countryCode") ? this.commonService.getSessionStorage("country") : '';
    this.continent = this.commonService.getSessionStorage("continent") ? this.commonService.getSessionStorage("country") : '';
    this.region = this.commonService.getSessionStorage("region") ? this.commonService.getSessionStorage("country") : '';
    this.city = this.commonService.getSessionStorage("city") ? this.commonService.getSessionStorage("country") : '';
    this.tenantid = this.commonService.getSessionStorage("tnt");
    if (this.tenantid == null || this.tenantid == '') {
      this.tenantid = this.commonService.getSessionStorage("tid");
    }

    this.patientid = this.commonService.getSessionStorage("pid") ? this.commonService.getSessionStorage("pid") : this.commonService.getSessionStorage("patientid") ? this.commonService.getSessionStorage("patientid") : '';
    this.auditObj.application = "QIntake";
    this.token1 = this.commonService.getSessionStorage('PI');
    this.workflow = (this.commonService.getSessionStorage("isPt")) ? '(Mobile)' : '(In-Office)';
  }

  writeOparationalLog(data: any, frmmodule?: any) {
    this.getSessionDetailsForAudit();
    let event = data.split('#');
    this.auditObj.city = this.city;
    this.auditObj.clientbrowser = getLogData.getbroweser() || '';
    this.auditObj.clientip = this.ip;
    this.auditObj.continent = this.continent;
    this.auditObj.country = this.country;
    this.auditObj.countrycode = this.countryCode;
    this.auditObj.region = this.region;
    this.auditObj.userid = this.userid;
    this.auditObj.userfullname = this.fullname;
    this.auditObj.loginuser = this.user;
    this.auditObj.transactionid = '';
    this.auditObj.tenantid = this.tenantid ? this.tenantid : '';
    this.auditObj.patientid = this.patientid ? this.patientid : '';
    this.ClientBrowser = getLogData.getbroweser();

    // console.log("fullname===========", this.fullname);
    // console.log("userid===========", this.userid);
    // console.log("user===========", this.user);
    // console.log("patientid===========", this.patientid);
    // console.log("isPatient===========", this.workflow);

    switch (event[0]) {
      case 'LockScreen':
        this.auditObj.message = this.workflow + 'Clicked on lock screen';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Clinic dashboard';
        this.auditObj.useraction = 'Clicked on lock screen';
        this.auditObj.transactionid = '0';
        this.callApI(this.auditObj);
        break;
      case 'Change Practice':
        this.auditObj.message = this.workflow + 'Clicked on change practice';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Clinic dashboard';
        this.auditObj.useraction = 'Clicked on change practice';
        this.auditObj.transactionid = event[1];
        this.callApI(this.auditObj);
        break;
      case 'Logout':
        this.auditObj.message = this.fullname + ' Signed off from Patient Intake';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Clinic dashboard';
        this.auditObj.useraction = 'Logout';
        this.auditObj.transactionid = this.userid;
        this.callApI(this.auditObj);
        break;
      case 'change location':
        this.auditObj.message = this.fullname + ' selected the location ' + event[2] + ' on practice dashboard';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Practice Dashboard';
        this.auditObj.useraction = 'Filter';
        this.auditObj.transactionid = event[1];
        this.callApI(this.auditObj);
        break;
      case 'search provider':
        this.auditObj.message = this.fullname + ' searched the Provider with a keyword ' + event[1] + ' on practice dashboard';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Practice Dashboard';
        this.auditObj.useraction = 'Search';
        this.auditObj.transactionid = '0';
        this.callApI(this.auditObj);
        break;
      case 'search patient':
        this.auditObj.message = this.fullname + ' searched the Patient with a keyword ' + event[1] + ' on practice dashboard';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Practice Dashboard';
        this.auditObj.useraction = 'Search';
        this.auditObj.transactionid = '0';
        this.callApI(this.auditObj);
        break;
      case 'filter patient':
        this.auditObj.message = this.fullname + ' selected the status filter ' + event[1] + ' on practice dashboard';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Practice Dashboard';
        this.auditObj.useraction = 'Filter';
        this.auditObj.transactionid = '0';
        this.callApI(this.auditObj);
        break;
      case 'select provider':
        this.auditObj.message = this.fullname + ' selected the provider ' + event[2] + ' on practice dashboard';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Practice Dashboard';
        this.auditObj.useraction = 'Filter';
        this.auditObj.transactionid = event[1]
        this.callApI(this.auditObj);
        break;
      case 'Logout':
        this.auditObj.message = this.workflow + 'clicked on logout';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Clinic dashboard';
        this.auditObj.useraction = 'Clicked on logout';
        this.auditObj.transactionid = event[1];
        this.callApI(this.auditObj);
        break;

      case 'lock tablet':
        this.auditObj.message = "Patient" + event[1] + " locked the tablet";
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Lock screen';
        this.auditObj.useraction = 'Lock';
        this.auditObj.transactionid = event[2] ? event[2] : '';
        this.callApI(this.auditObj);
        break;

      case 'Confirm Details-Yes':
        this.auditObj.message = 'Patient ' + event[2] + ' confirmed the demographics is correct';

        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Pre-Registration - Demographics';
        this.auditObj.useraction = event[1];
        this.auditObj.transactionid = event[3] ? event[3] : '0';
        this.callApI(this.auditObj);
        break;
      case 'Confirm Details-No':
        // console.log(this.workflow + 'clicked on patient demographics ' + event[1] + ' button');
        this.auditObj.message = 'Patient ' + event[2] + ' selected to update the demographics';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Pre-Registration - Demographics';
        this.auditObj.useraction = event[1];
        this.auditObj.transactionid = event[3] ? event[3] : '0';
        this.callApI(this.auditObj);
        break;
      case 'Confirm Details-Click':
        // console.log(this.workflow + 'clicked on patient demographics ' + event[1] + ' button');
        this.auditObj.message = 'Patient ' + event[2] + ' clicked Edit to update the demographics';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Pre-Registration - Demographics';
        this.auditObj.useraction = event[1];
        this.auditObj.transactionid = event[3] ? event[3] : '0';
        this.callApI(this.auditObj);
        break;
      case 'demographic cancel':
        //console.log("==============================", event[0]);

        this.auditObj.message = "patient " + event[1] + ' discarded the modified demographics information';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Pre-Registration - Demographics';
        this.auditObj.useraction = 'Edit';
        this.auditObj.transactionid = event[2];
        this.callApI(this.auditObj);
        break;
      case 'provider list':
        //console.log("==============================", event[0]);

        this.auditObj.message = "patient " + event[1] + ' Access the referral provider list';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'provider list - Demographics';
        this.auditObj.useraction = 'Edit';
        this.auditObj.transactionid = event[2];
        this.callApI(this.auditObj);
        break;

      case 'pcp list':
        //console.log("==============================", event[0]);

        this.auditObj.message = "patient " + event[1] + ' Access the primary care provider list';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'primary care provider list - Demographics';
        this.auditObj.useraction = 'Edit';
        this.auditObj.transactionid = event[2];
        this.callApI(this.auditObj);
        break;

      case 'preferred pharmacy list':
        //console.log("==============================", event[0]);

        this.auditObj.message = "patient " + event[1] + ' Access the preferred pharmacy list';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'preferred pharmacy list - Demographics';
        this.auditObj.useraction = 'Edit';
        this.auditObj.transactionid = event[2];
        this.callApI(this.auditObj);
        break;

      case 'Delete referring provider':
        this.auditObj.message = 'Patient ' + event[1] + ' referring provider has been deleted';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Demographics';
        this.auditObj.useraction = 'Delete';
        this.auditObj.transactionid = event[2] ? event[2] : '';
        this.callApI(this.auditObj);
        break;
      case 'Delete pcp':
        this.auditObj.message = 'Patient ' + event[1] + ' primary care provider has been deleted';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Demographics';
        this.auditObj.useraction = 'Delete';
        this.auditObj.transactionid = event[2] ? event[2] : '';
        this.callApI(this.auditObj);
        break;
      case 'Delete preferred pharmacy':
        this.auditObj.message = 'Patient ' + event[1] + ' preferred pharmacy has been deleted';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Demographics';
        this.auditObj.useraction = 'Delete';
        this.auditObj.transactionid = event[2] ? event[2] : '';
        this.callApI(this.auditObj);
        break;
      case 'updated referring provider':
        this.auditObj.message = 'Patient ' + event[1] + ' referring provider has been updated';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Demographics';
        this.auditObj.useraction = 'edit';
        this.auditObj.transactionid = event[2] ? event[2] : '';
        this.callApI(this.auditObj);
        break;
      case 'updated primary care provider':
        this.auditObj.message = 'Patient ' + event[1] + ' primary care provider has been updated';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Demographics';
        this.auditObj.useraction = 'edit';
        this.auditObj.transactionid = event[2] ? event[2] : '';
        this.callApI(this.auditObj);
        break;
      case 'updated preferred pharmacy':
        this.auditObj.message = 'Patient ' + event[1] + ' preferred pharmacy has been updated';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Demographics';
        this.auditObj.useraction = 'edit';
        this.auditObj.transactionid = event[2] ? event[2] : '';
        this.callApI(this.auditObj);
        break;
      case 'update demographics':
        //console.log("E ==> ", event)
        this.auditObj.message = 'Patient ' + event[2] + ' updated the demographics information ';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Pre-Registration - Demographics';
        this.auditObj.useraction = event[1];
        this.auditObj.transactionid = event[3] ? event[3] : '0';
        this.callApI(this.auditObj);
        break;
      case 'completed demographics':
        this.auditObj.message = 'Patient ' + event[2] + ' completed the first step (Demographics Info)';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Pre-Registration - Demographics';
        this.auditObj.useraction = event[1];
        this.auditObj.transactionid = event[3] ? event[3] : '0';
        this.callApI(this.auditObj);
        break;
      case 'Add Later':
        this.auditObj.message = this.workflow + 'clicked on add later button';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Info confirm';
        this.auditObj.useraction = 'Click';
        this.auditObj.transactionid = event[1];
        this.callApI(this.auditObj);
        break;
      case 'Add Insurance':
        this.auditObj.message = 'Patient ' + event[1] + ' clicked Add another Insurance to add new Insurance.';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Pre-Registration - Insurance ';
        this.auditObj.useraction = 'Add';
        this.auditObj.transactionid = event[2] ? event[2] : '';
        // event[3] ? event[3] : '0';
        this.callApI(this.auditObj);
        break;
      case 'insurance cancel':
        this.auditObj.message = "Patient " + event[1] + ' discarded the modified Insurance information';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Pre-Registration - Insurance ';
        this.auditObj.useraction = 'Edit';
        this.auditObj.transactionid = event[2] ? event[2] : '0';
        this.callApI(this.auditObj);
        break;
      case 'insurance completed':
        this.auditObj.message = "Patient " + event[1] + ' completed the second step (Insurance collection)';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Pre-Registration - Insurance ';
        this.auditObj.useraction = 'Complete';
        this.auditObj.transactionid = event[2] ? event[2] : '0';
        this.callApI(this.auditObj);
        break;
      case 'insurance popup':
        this.auditObj.message = "Patient " + event[2] + ' updated the Insurance information';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Pre-Registration - Insurance ';
        this.auditObj.useraction = 'Edit';
        this.auditObj.transactionid = event[3] ? event[3] : '0';
        this.callApI(this.auditObj);
        break;

      case 'delete insurance':
        this.auditObj.message = "Patient " + event[1] + ' insurance has been deleted';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Pre-Registration - Insurance-info-confirm ';
        this.auditObj.useraction = 'delete';
        this.auditObj.transactionid = event[2] ? event[2] : '0';
        this.callApI(this.auditObj);
        break;

      case 'confirm Insurance-Yes':
        this.auditObj.message = 'Patient ' + event[2] + ' confirmed the Insurance is correct';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Pre-Registration - Insurance ';
        this.auditObj.useraction = event[1];
        this.auditObj.transactionid = event[3] ? event[3] : ''
        this.callApI(this.auditObj);
        break;
      case 'confirm Insurance-No':
        this.auditObj.message = 'Patient ' + event[2] + ' selected to update the Insurance';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Pre-Registration - Insurance ';
        this.auditObj.useraction = event[1];
        this.auditObj.transactionid = event[3] ? event[3] : ''
        this.callApI(this.auditObj);
        break;
      case 'confirm Insurance-Later':
        this.auditObj.message = 'Patient ' + event[2] + ' selected to update the Insurance Later';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Pre-Registration - Insurance ';
        this.auditObj.useraction = event[1];
        this.auditObj.transactionid = event[3] ? event[3] : ''
        this.callApI(this.auditObj);
        break;
      case 'Logout':
        this.auditObj.message = this.workflow + 'clicked on logout';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Clinic dashboard';
        this.auditObj.useraction = 'Click';
        this.auditObj.transactionid = event[1];
        this.callApI(this.auditObj);
        break;
      case 'Fill out forms':
        this.auditObj.message = this.workflow + 'clicked on fill out forms : ' + event[1];
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Info confirm';
        this.auditObj.useraction = 'Click';
        this.auditObj.transactionid = event[1];
        this.callApI(this.auditObj);
        break;
      case 'Fill Form Details':
        this.auditObj.message = "Patient " + event[1] + ' completed the form ' + event[2] + ' & moved to next form';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Pre-Registration - Forms';
        this.auditObj.useraction = 'Save, Next';
        this.auditObj.transactionid = event[3] ? event[3] : '0';
        this.callApI(this.auditObj);
        break;
      case 'Fill Form Saved':
        this.auditObj.message = "Patient " + event[1] + ' completed the form ' + event[2];
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Pre-Registration - Forms';
        this.auditObj.useraction = 'Save';
        this.auditObj.transactionid = event[3] ? event[3] : '0';
        this.callApI(this.auditObj);
        break;
      case 'Fill Form Back':
        this.auditObj.message = "Patient " + event[1] + ' came back on form ' + event[2] + ' to view/update information';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Pre-Registration - Forms';
        this.auditObj.useraction = 'Back';
        this.auditObj.transactionid = event[3] ? event[3] : '0';
        this.callApI(this.auditObj);
        break;
      case 'Not Update Popup':
        this.auditObj.message = this.workflow + 'clicked on "Ok" button';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Patient pre-register info popup';
        this.auditObj.useraction = 'Click';
        this.auditObj.transactionid = event[1];
        this.callApI(this.auditObj);
        break;
      case 'Review Patient Info':
        this.auditObj.message = `${event[1] ? event[1] : ""} accepted the changes for Patient ${event[2] ? event[2] : ""}`;
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Review patient information';
        this.auditObj.useraction = 'Click';
        this.auditObj.transactionid = event[3] ? event[3] : '';
        this.callApI(this.auditObj);
        break;
      case 'Review Patient Close':
        console.log("p ==? ", event)
        this.auditObj.message = event[1] + ' closed the Patient Info Review Screen for Patient ' + event[3];
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Information Review Screen';
        this.auditObj.useraction = 'Close';
        this.auditObj.transactionid = event[2] ? event[2] : '';
        this.callApI(this.auditObj);
        break;

      case 'Fill Form':
        this.auditObj.message = "Patient " + event[1] + ' started filling form: ' + event[2];
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Pre-Registration - Forms';
        this.auditObj.useraction = 'Start';
        this.auditObj.transactionid = event[3] ? event[3] : '';
        this.callApI(this.auditObj);
        break;
      case 'Form Summary':
        this.auditObj.message = "Patient " + event[1] + ' viewed the forms summary & confirmed as completed ';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Pre-Registration - Forms ';
        this.auditObj.useraction = 'Confirm';
        this.auditObj.transactionid = event[3] ? event[3] : '';
        this.callApI(this.auditObj);
        break;
      case 'Form Done':
        this.auditObj.message = "Patient " + event[1] + ' completed the forth step (Forms)';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Pre-Registration - Forms';
        this.auditObj.useraction = 'Complete';
        this.auditObj.transactionid = event[3] ? event[3] : '';
        this.callApI(this.auditObj);
        break;
      case 'insurance edit':
        this.auditObj.message = "Patient " + event[1] + ' clicked Edit to update the Insurance';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Pre-Registration - Insurance ';
        this.auditObj.useraction = 'Edit';
        this.auditObj.transactionid = event[2] ? event[2] : '0';
        this.callApI(this.auditObj);
        break;
      case 'Login User':
        this.auditObj.message = event[2] + ' Signed in to Patient Intake';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Practice Login';
        this.auditObj.useraction = 'Login';
        this.auditObj.transactionid = event[1] ? event[1] : '0';
        // this.auditObj.userfullname = event[2];
        // this.auditObj.userid = event[1];
        this.callApI(this.auditObj);
        break;
      case 'Connect To Tenant':
        this.auditObj.message = this.fullname + ' conneted to ' + event[1];
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Practice Selection';
        this.auditObj.useraction = 'Select';
        this.auditObj.transactionid = event[2] ? event[2] : '0';
        this.callApI(this.auditObj);
        break;
      case 'Verify DOB':
        this.auditObj.message = 'Patient ' + event[1] + ' verification ' + event[2];
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'DOB Verification';
        this.auditObj.useraction = 'Verify';
        this.auditObj.transactionid = '0';
        this.callApI(this.auditObj);
        break;
      case 'Welcome Screen':
        this.auditObj.message = 'Patient ' + event[1] + ' viewed Welcome screen ';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Pre-Registration - Home';
        this.auditObj.useraction = 'View';
        this.auditObj.transactionid = '0';
        this.callApI(this.auditObj);
        break;
      case 'Start Button':
        console.log("Ev ==> ,e", event)
        this.auditObj.message = 'Patient ' + event[2] + ' started Pre-registration session';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Patient Welcome';
        this.auditObj.useraction = event[1];
        this.auditObj.transactionid = event[3] ? event[3] : '0';
        this.callApI(this.auditObj);
        break;
      case 'All Done':
        this.auditObj.message = 'Patient ' + event[1] + ' is Pre-registered by completing all the steps ';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Pre-Registration - Complete';
        this.auditObj.useraction = 'Complete';
        this.auditObj.transactionid = event[2] ? event[2] : '';
        this.callApI(this.auditObj);
        break;
      case 'Patient Selected':
        this.auditObj.message = event[1] + ' selected a Patient ' + event[2] + ' on practice dashbaord to start Pre-Registration';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Practice Dashboard';
        this.auditObj.useraction = 'Select';
        this.auditObj.patientid = event[3] ? event[3] : '';
        this.auditObj.transactionid = event[3] ? event[3] : '';
        this.callApI(this.auditObj);
        break;

      case 'Update Driving Card':
        this.auditObj.message = 'Patient ' + event[1] + ' Driving license has been added';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Driving License Card';
        this.auditObj.useraction = 'Add';
        this.auditObj.transactionid = event[2] ? event[2] : '';
        this.callApI(this.auditObj);
        break;

      case 'Update Insurance Card':
        this.auditObj.message = 'Patient ' + event[1] + ' Insurance card has been added';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'Insurance Card';
        this.auditObj.useraction = 'Add';
        this.auditObj.transactionid = event[2] ? event[2] : '';
        this.callApI(this.auditObj);
        break;

      case 'Delete Insurance Card':
        this.auditObj.message = 'Patient ' + event[1] + ' Insurance card has been deleted';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'View Insurance Card';
        this.auditObj.useraction = 'Delete';
        this.auditObj.transactionid = event[2] ? event[2] : '';
        this.callApI(this.auditObj);
        break;


      case 'Delete Driving Card':
        this.auditObj.message = 'Patient ' + event[1] + ' Driving license has been deleted';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'View Driving License Card';
        this.auditObj.useraction = 'Delete';
        this.auditObj.transactionid = event[2] ? event[2] : '';
        this.callApI(this.auditObj);
        break;

      case 'Access Audit Report': {
        this.auditObj.message = this.workflow + 'accessed audit report';
        this.auditObj.module = 'Audit report module';
        this.auditObj.screen = 'Audit report';
        this.auditObj.useraction = 'View';
        this.auditObj.transactionid = '0';
        this.callApI(this.auditObj);
        // console.log("this.audit CG *********************   ", this.auditObj);
        break;
      }
      case 'Access Mobile Link':
        //console.log("E ==> ", event)
        this.auditObj.message = 'Patient ' + event[2] + 'has access the mobile link';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'mobile verification';
        this.auditObj.useraction = event[1];
        this.auditObj.transactionid = event[3] ? event[3] : '0';
        this.callApI(this.auditObj);
        break;
      case 'send code buton ':
        this.auditObj.message = this.workflow + 'clicked on send code button';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'mobile verification screen';
        this.auditObj.useraction = 'Click';
        this.auditObj.transactionid = event[1];
        this.callApI(this.auditObj);
        break;
      case 'Access otp verification':
        //console.log("E ==> ", event)
        this.auditObj.message = 'Patient ' + event[2] + 'has access the otp verification screen';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'otp verification';
        this.auditObj.useraction = event[1];
        this.auditObj.transactionid = event[3] ? event[3] : '0';
        this.callApI(this.auditObj);
        break;
      case 'otp verification button':
        this.auditObj.message = this.workflow + 'clicked on otp verification button';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'otp verification screen';
        this.auditObj.useraction = 'Click';
        this.auditObj.transactionid = event[3] ? event[3] : '0';
        this.callApI(this.auditObj);
        break;
      case 'otp verification success status ':
        this.auditObj.message = this.workflow + ' otp verification success ';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'otp verification screen';
        this.auditObj.useraction = 'status';
        this.auditObj.transactionid = event[3] ? event[3] : '0';
        this.callApI(this.auditObj);
        break;
      case 'otp verification failed status ':
        this.auditObj.message = this.workflow + ' otp verification failed ';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'otp verification screen';
        this.auditObj.useraction = 'status';
        this.auditObj.transactionid = event[3] ? event[3] : '0';
        this.callApI(this.auditObj);
        break;
      case 'access portal form':
        this.auditObj.message = this.workflow + ' access the portal form link ';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'info-confirm screen';
        this.auditObj.useraction = 'Click';
        this.auditObj.transactionid = event[3] ? event[3] : '0';
        this.callApI(this.auditObj);
        break;
      case 'complete portal form':
        this.auditObj.message = this.workflow + 'portal form has been completed ';
        this.auditObj.module = 'Patient Intake ' + this.workflow;
        this.auditObj.screen = 'portal form screen';
        this.auditObj.useraction = 'done';
        this.auditObj.transactionid = event[3] ? event[3] : '0';
        this.callApI(this.auditObj);
        break;
      case 'Edit appointment status':
        this.auditObj.message = this.fullname + ' Update the status to ' + event[1];
        this.auditObj.module = 'Audit report module';
        this.auditObj.screen = 'Audit report';
        this.auditObj.useraction = 'Edit';
        this.auditObj.transactionid = '';
        this.callApI(this.auditObj);
        break;

      default:
        // Code for default case
        break;
    }
  }

  callApI(para: any) {
    this.http.post<any>(environment.auditUrl + "WriteAuditLog", para).subscribe(res => {

    })
  }
}
