<!-- <app-welcome-header></app-welcome-header> --> 
<div #captchaContainer>
  <div id="recaptcha-container"></div>

</div>
<div class="mid-container pos-relative">
  <div *ngIf="loadingPanelVisible"  class="k-i-loading loading-overlay myLoader"></div>
  <ion-grid class="mid-comment-border mid-header-section">
    <div class="contentBox">
      <form class="verification-box column" [formGroup]="PhoneForm">
        <ion-text class="bodyXL-txt-bold mb-10" color="gray100">Get a verification code</ion-text>
        <ion-text class="bodyM-txt-italic mb-20" color="gray80">
          To protect your health information, we need to send a verification code to your mobile phone.
        </ion-text>
        <div class="ion-text-center ">
          <kendo-maskedtextbox [style.width.px]="170" class="bodyL-txt p-7" color="gray100" [mask]="mask"
            formControlName="phone" [(ngModel)]="phoneNumber">
            <!-- [readonly]="true" -->
          </kendo-maskedtextbox>
          <kendo-formerror class="bodyM-txt mx-30" *ngIf="iserrorShow">Enter correct mobile no.

          </kendo-formerror>
        </div>
        <div *ngIf="errormsg" class="required mt-3">{{errormsg}}</div>
        <div class="ion-text-center my-20 w-100">
          <!-- <button [disabled]="!PhoneForm.valid"  fill="solid" color="ion-color-white" class="filled-icon-btn capitalize w-100 d-flex" (click)="getPatientInfo()">
                <ion-text>Send Code</ion-text>
                <ion-icon src="../../../../../../../assets/svg/arrow-right.svg" class="ml-5"></ion-icon>
              </button> -->
          <ion-button *ngIf="!isClicked" [disabled]="!PhoneForm.valid" fill="solid" color="ion-color-white"
            class="filled-icon-btn capitalize w-100" (click)="getPatientInfo()">
            <ion-text>Send Code  </ion-text>
            <ion-icon src="../../../../../../../assets/svg/arrow-right.svg" class="ml-5"></ion-icon>
          </ion-button>

          <ion-button *ngIf="isClicked" [disabled]="PhoneForm.valid && isClicked" fill="solid" color="ion-color-white"
            class="filled-icon-btn capitalize w-100">
            <ion-text>Send Code</ion-text>
            <ion-icon src="../../../../../../../assets/svg/arrow-right.svg" class="ml-5"></ion-icon>
          </ion-button>
        </div>
      </form>
    </div>
  </ion-grid>
</div>
 