import { Component, OnInit } from '@angular/core';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { WindowService } from '@progress/kendo-angular-dialog';
import { PatientIntakeQueModel } from 'src/app/core/models/intakeQueModel';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { PatientService } from 'src/app/core/services/patient.service';
import firebase from 'firebase/compat/app';
import { CommonService } from 'src/app/core/services/common.service';
import { catchError, forkJoin, map, of, switchMap } from 'rxjs';
import { PaymentService } from 'src/app/services/payment.service';
import { PracticeService } from 'src/app/services/practice.service';
import { AuditService } from 'src/app/main/pages/AuditService/audit.service';

@Component({
  selector: 'otp-verify',
  templateUrl: './otp-verify.component.html',
  styleUrls: ['./otp-verify.component.scss'],
})
export class OtpVerifyComponent implements OnInit {
  public loadingPanelVisible: boolean = false;
  verifyBtn: boolean = false;
  isShowError: boolean = false;
  phoneNumber: any = '';
  getPhoneNumber: any;
  recaptchaVerifier!: firebase.auth.RecaptchaVerifier;
  phonenumber: any;
  tenantId: any;
  patientId: any;
  sid: any;
  isSendButtonEnabled: boolean = false;
  verificationError!: boolean;
  getRes: any;
  appointmentId: any;
  isDisabled = true;
  countdown: number = 0;
  numberPattern = /^\d+$/;
  otpform: FormGroup;
  patientDetails: any;
  insuranceDetails: any;
  patientQue!: PatientIntakeQueModel;
  patienitDetails: any;
  primaryInsurance: any;
  secondaryInsurance: any;
  ptoVerified: boolean = true;
  tertiaryInsurance: any;
  formsList: any;
  appointmentData: any;
  practiceData: any;

  constructor(private route: Router,
    private auth: AngularFireAuthModule,
    private fireService: FirebaseService,
    private patientService: PatientService,
    private fb: FormBuilder,
    private windowService: WindowService,
    private commonService: CommonService,
    private paymentService: PaymentService,
    public practiceService: PracticeService,
  private auditService: AuditService) {
    this.otpform = this.fb.group({
      code: ['', [Validators.required,
      Validators.pattern(/^\d{6}$/)]],
      fullName: ['']
    });
    // this.disableBackButton();
  }

  ngOnInit() {
    this.sid = this.commonService.getSessionStorage('sid');
    this.tenantId = this.commonService.getSessionStorage('tid');
    this.patientId = this.commonService.getSessionStorage('pid');
    // this.getPatientAuthenticationDetails();
    // this.phoneNumber = localStorage.getItem('phone');
    let patientPhone = this.commonService.getSessionStorage('prn')
    this.auditService.writeOparationalLog('Access otp verification#Access#' + this.patientId + "#" +  this.sid);

    this.getPhoneNumber = this.formatPhoneNumber(patientPhone);
    this.getAppointmentDetails();
    if (this.tenantId && this.patientId) {
      // this.savePatientDemoAndInsurance(this.tenantId, this.patientId);
      this.savePatientDemoDetails(this.tenantId, this.patientId);
      this.savePatientInsuDetails(this.tenantId, this.patientId);
      // this.savePatientFormsDetails(this.sid, this.patientId);
    }
    this.getPracticeDetails(this.tenantId)
    this.fireService.getCountdown().subscribe(countdown => {
      this.countdown = countdown;
      if (this.countdown == 0) {
        this.isDisabled = false;
        this.isSendButtonEnabled = true;
      }
    });
  }

  formatPhoneNumber(getPhoneNumber: any) {
    const digitsOnly = getPhoneNumber?.replace(/\D/g, '');
    const formattedNumber = `(${digitsOnly.slice(0, 3)})-${digitsOnly.slice(3, 6)}-${digitsOnly.slice(6)}`;
    return formattedNumber;
  }

  //function for resend otp 
  resendOTP() {
    this.sid = this.commonService.getSessionStorage('sid');
    this.route.navigateByUrl('/login/' + this.sid);
  }

  checkInputLength() {
    this.isSendButtonEnabled = (this.otpform.get('code')?.value).length === 6;
  }

  //function for to get patient details for autherization purpose
  // getPatientAuthenticationDetails() {
  //   this.patientService.getRefById(this.sid).subscribe((res: any) => {
  //     if (res) {
  //       this.getRes = res;
  //       this.patientId = this.getRes.pid;
  //       this.appointmentId = this.getRes.aid;
  //       this.tenantId = this.getRes.tid;
  //       sessionStorage.setItem('pid', this.patientId);
  //       sessionStorage.setItem('aid', this.appointmentId);
  //       sessionStorage.setItem('tid', this.tenantId);
  //     }
  //   });
  // }

  //function for to verify otp and autheticate pateint based on pid,tid,aid
  otpVerification() {
    this.auditService.writeOparationalLog('otp verification button#click#'  + this.patientId + "#" +  this.sid);

    this.loadingPanelVisible=true;
    this.isSendButtonEnabled = true
    this.fireService.enterVerificationCode(this.otpform.value.code).then(async (response: any) => {
      // this.route.navigateByUrl('/app/welcome');
      //console.log("respons", response)
      // console.log("respons",response.multiFactor.user.accessToken)
      if (response) {
        this.commonService.setLocalStorage('user', JSON.stringify(response));
        this.commonService.setLocalStorage('type', '2');
        this.saveSession();
        this.isSendButtonEnabled = false
        // await this.clearGageCredentials(this.tenantId);
         this.loadingPanelVisible=false;
         this.route.navigateByUrl('/app/welcome');
         this.auditService.writeOparationalLog('otp verification success status #success#'  + this.patientId + "#" +  this.sid);

        this.commonService.setSessionStorage('ptoVerified', JSON.stringify(this.ptoVerified))
        // this.commonService.setSessionStorage('token', JSON.stringify(response._delegate.accessToken));
        // this.verificationError = false;
        // sessionStorage.setItem('uid', response.uid);
        // sessionStorage.setItem('token', response.multiFactor.user.accessToken);
        // if (this.patientId !== undefined && this.appointmentId !== undefined && this.tenantId != undefined) {
        //   this.savePatientDemoAndInsurance()
        //   this.route.navigateByUrl('/app/welcome');

        // }
        // if (sessionStorage.getItem('uid') !== null) {
        //   // this.windowService.setUserLoggedIn(true);
        // }
        // else {
        //   // this.windowService.setUserLoggedIn(false);
        // }
      }
    }, (error: Error) => {
      if (error) {
        this.auditService.writeOparationalLog('otp verification failed status #success#' + this.patientId + "#" +  this.sid);

         this.loadingPanelVisible=false;
        this.verificationError = true;
        this.isSendButtonEnabled = false
      }
    });
  }

  async clearGageCredentials(tenantid: any) {
    this.paymentService.getTenantClearGageCredentials(tenantid).pipe(
      catchError(error => {
        console.error('Error occurred while fetching ClearGage credentials:', error);
        return of(null); // Return an empty observable to continue the stream
      })
    ).subscribe((response: any) => {
      if (response) {
        const cleargageCredentials = JSON.stringify(response.data);
        this.commonService.setSessionStorage('tenantcleargageCred', cleargageCredentials);
      } else {
        this.commonService.removeSessionStorageBykey('tenantcleargageCred');
      }
    });
  }

  savePatientDemoDetails(tenantId: any, patientid: any) {
    this.patientService.patientDemographicDetails(patientid).subscribe((response: any) => {
      if (response) {
        this.patienitDetails = response;

        let obj = {
          sessionid: this.commonService.getSessionStorage('sid'),
          patientid: patientid,
          tenantid: tenantId,
          firstname: this.patienitDetails?.firstname,
          middlename: this.patienitDetails?.middlename,
          lastname: this.patienitDetails?.lastname,
          addressline1: this.patienitDetails?.addressline1,
          addressline2: this.patienitDetails?.addressline2,
          gender: this.patienitDetails?.gender,
          city: this.patienitDetails?.city,
          state: this.patienitDetails?.state,
          zip: this.patienitDetails?.zip,
          county: this.patienitDetails?.county,
          phone: this.patienitDetails?.phone,
          mobile: this.patienitDetails?.mobile,
          dob: this.patienitDetails?.dob,
          email: this.patienitDetails?.email,
          semergencycontact: this.patienitDetails?.semergencycontact,
          semergencymobile: this.patienitDetails?.semergencymobile,
          updatedby: this.patientId,
        };
        this.patientService.savePatientDemoIntakeQue(obj).subscribe((res: any) => {
        });
      }
    });
  }

  savePatientInsuDetails(tenantId: any, patientid: any) {
    this.patientService.getPatientDetails(tenantId, patientid).pipe(
      switchMap((res) => {
        if (!res) {
          throw new Error("Patient details not found");
        }

        this.insuranceDetails = res;

        //const insuranceObjects: any[] = [];

        const mapInsurance = (insurance: any, insuranceflag: any) => {
          return this.patientService.getInsuranceCompanyDetails(insurance?.sinsuranceplanid, tenantId).pipe(
            map((response) => {
              return {
                sessionid: this.commonService.getSessionStorage('sid'),
                patientid: patientid,
                tenantid: tenantId,
                insuranceplanid: insurance?.sinsuranceplanid,
                insuranceplanname: insurance?.sinuranceplanname,
                insuranceflag,
                subscriberid: insurance?.ssubscriberid,
                group: insurance?.sgroup,
                subfname: insurance?.ssubfname ? insurance?.ssubfname : '',
                submname: insurance?.ssubmname ? insurance?.ssubmname : '',
                sublname: insurance?.ssublname ? insurance?.ssublname : '',
                relationship: insurance?.srelationship,
                subscriberdob: insurance?.dtdob,
                copay: insurance?.ncopay,
                coveragepercent: insurance?.ncoveragepercent,
                deductableamount: insurance?.ndeductableamount,
                insurancecompanyid: response?.insurancecompanyid,
                insurancecompanyname: response?.insurancecompanyname,
                payerid: insurance?.spayerid,
                relationshipid: insurance?.nrelationshipid,
                subscribergender: insurance?.ssubscribergender,
                instypecodedefault: insurance?.sinstypecodedefault,
                instypedescdefault: insurance?.sinstypedescdefault

              };
            })
          );
        };

        return forkJoin(
          this.insuranceDetails.map((x: any) => {
            if (x.ninsuranceflag === 1) {
              this.primaryInsurance = x;
              return mapInsurance(this.primaryInsurance, 1);
            } else if (x.ninsuranceflag === 2) {
              this.secondaryInsurance = x;
              return mapInsurance(this.secondaryInsurance, 2);
            } else if (x.ninsuranceflag === 3) {
              this.tertiaryInsurance = x;
              return mapInsurance(this.tertiaryInsurance, 3);
            }
            return {};
          })
        );
      }),
      switchMap((insuranceObjects) => {
        return this.patientService.savePatientInsuIntakeQue(insuranceObjects);
      })
    ).subscribe(
      (res: any) => {

      },
      (error) => {
        console.error('Error:', error);

      }
    );
  }

  savePatientFormsDetails(sid: any, patientId: any, appointmentId: any) {

    this.patientService.getFormbyType(this.tenantId, appointmentId).subscribe((res: any) => {
      if (res) {
        this.formsList = res;
        this.formsList.forEach((form: any) => {
          if (form) {
            let formObj = {
              "submittedby": null,
              "submittedbyid": null,
              "ownerid": patientId,
              "ownertype": "patient",
              "form_id": form.id,
              "sessionid": sid,
              "answermodel": null,
              "documentid": null,
              "storagefilename": null,
              "medialink": null,
              "signature": null
            };
            this.patientService.saveForms(formObj).subscribe((res) => {

            });
          };
        });
      };

    });
  }
  verifyCode(formdata: any) {

    this.route.navigateByUrl('/app/welcome');
  }
  disableBackButton() {
    history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function () {
      history.pushState(null, document.title, window.location.href);
    });
  }
  getPracticeDetails(TenantId: any) {
    this.practiceService.getPracticeDetailsByTenantId(TenantId).subscribe((res: any) => {
      if (res) {
        this.practiceData = res;
        // localStorage.setItem("appointmentdetails", JSON.stringify(appointment))
      }
    });
  }
  getAppointmentDetails() {
    this.patientService.getAppointmentDetails(this.sid).subscribe((res: any) => {
      if (res) {
        this.appointmentData = res;
        if (this.tenantId && this.patientId)
          this.savePatientFormsDetails(this.sid, this.patientId, res.appointmenttypeid);

      }
    });
  }
  saveSession() {
    let session = {
      "sessionid": this.sid,
      "sessionstartedby": this.patientId,
      "sourceworkflow": "Self",
      "tenantid": this.tenantId,
      "tenantname": this.practiceData?.tenantname,
      "locationid": this.appointmentData?.locationid,
      "locationname": this.appointmentData?.locationname,
      "appointmenttypeid": this.appointmentData?.appointmenttypeid,
      "appointmenttypedescription": this.appointmentData?.appointmenttypedesc

    };
    this.patientService.saveSession(session).subscribe((res) => {

    });
  }

  onInput(event: any) {
    let inputValue = event.target.value.replace(/\D/g, '');
    if (inputValue.length > 6) {
      inputValue = inputValue.slice(0, 6); // Limit to 6 characters
    }
    this.otpform.get('code').setValue(inputValue); // Update the form control value
  }

  isInputValid(): boolean {
    const inputValue = this.otpform.get('code')?.value;
    return inputValue && /^\d{6}$/.test(inputValue);
  }


}
